body {
  color: #636363;
  font-size: 14px;
  font-family: "Open Sans", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  color: #17183b;
  font-weight: 700; }

a {
  color: #17183b;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s; }

.btn {
  min-width: 150px;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  letter-spacing: 1.1px;
  padding: 12px 30px;
  border-radius: 0;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700; }
  .btn.btn-primary {
    color: #FFFFFF;
    background-color: #17183b;
    border-color: #17183b; }

p {
  line-height: 24px; }

.section-padding {
  padding: 35px 0px; }

.title-line {
  position: relative;
  font-weight: 700;
  margin-bottom: 30px; }
  .title-line:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 4px;
    left: 0;
    right: 0;
    bottom: -10px;
    background-color: #17183b; }

.lead {
  color: #636363; }

.error-page {
  padding: 194px 60px; }
  .error-page h1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 72px; }
  .error-page h3 {
    font-size: 24px;
    font-weight: 700;
    line-break: 24px;
    margin-top: 0; }
  .error-page p {
    font-size: 14px; }
  .error-page .btn {
    margin-top: 20px;
    font-weight: 700; }

.mt-60 {
  margin-top: 60px; }

.form-group {
  margin-bottom: 20px; }
  .form-group .form-control {
    font-size: 14px;
    border-radius: 0;
    border: 1px solid #E7E7E7;
    padding: 0.5rem 1rem; }
    .form-group .form-control.is-invalid {
      border-color: #dc3545; }
    .form-group .form-control.is-valid {
      border-color: #28a745; }
    .form-group .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(23, 24, 59, 0.5); }
    .form-group .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(23, 24, 59, 0.5); }
    .form-group .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(23, 24, 59, 0.5); }
    .form-group .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(23, 24, 59, 0.5); }

input#dhsecrethoney {
  display: none; }

.list-group .list-item {
  list-style: none;
  margin-bottom: 5px; }
  .list-group .list-item i {
    position: relative;
    display: inline-block;
    width: 35px;
    height: auto;
    font-size: 14px; }
    .list-group .list-item i:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 4px;
      width: 2px;
      height: 12px;
      background-color: #636363; }
  .list-group .list-item span {
    margin-left: 8px; }

.quote {
  display: block;
  background-color: #EDEDED;
  padding: 25px 35px;
  margin: 30px 0px; }
  .quote:before {
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }

.alert {
  border-radius: 0; }

.navbar {
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 0; }
  .navbar .navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px; }
  .navbar .nav-item .dropdown-button {
    display: none; }
  .navbar .nav-item.dropdown:hover .dropdown-menu {
    left: auto; }
    .navbar .nav-item.dropdown:hover .dropdown-menu li {
      height: 39px; }
  .navbar .nav-item .nav-link {
    padding: 33px 18px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1; }
    .navbar .nav-item .nav-link.dropdown-toggle:after {
      content: unset; }
    .navbar .nav-item .nav-link.btn-primary {
      padding: 15px;
      margin: 18px 0px 18px 18px;
      color: #FFFFFF;
      background-color: #17183b;
      border-color: #17183b; }
  .navbar .nav-item .dropdown-menu {
    display: block;
    left: -9999px;
    border: none;
    border-radius: 0;
    margin: 0; }
    .navbar .nav-item .dropdown-menu li {
      height: 0;
      transition: all .25s ease;
      -webkit-transition: all .25s ease;
      -ms-transition: all .25s ease; }
      .navbar .nav-item .dropdown-menu li .dropdown-item {
        padding: 10px 20px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase; }
        .navbar .nav-item .dropdown-menu li .dropdown-item:hover {
          background-color: #EDEDED;
          color: #17183b; }

@media screen and (max-width: 767px) {
  .navbar {
    position: relative;
    padding-left: 15px;
    padding-right: 15px; }
    .navbar .mobile-header {
      position: relative;
      width: 100%;
      height: auto;
      padding: 5px 0; }
      .navbar .mobile-header .navbar-toggler {
        position: absolute;
        top: 25px;
        right: 0;
        width: 55px;
        height: 46px;
        z-index: 9999;
        outline: 0;
        cursor: pointer; }
        .navbar .mobile-header .navbar-toggler:before {
          position: absolute;
          top: 17px;
          right: 10px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: #17183b;
          content: ''; }
        .navbar .mobile-header .navbar-toggler .navbar-toggler-span {
          position: absolute;
          top: 23px;
          right: 10px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: #17183b;
          content: ''; }
        .navbar .mobile-header .navbar-toggler:after {
          position: absolute;
          top: 29px;
          right: 10px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: #17183b;
          content: ''; }
    .navbar .navbar-nav {
      width: 100%; }
      .navbar .navbar-nav .nav-item {
        position: relative;
        width: 100%;
        height: auto;
        border-top: 1px solid #EDEDED; }
        .navbar .navbar-nav .nav-item .nav-link {
          padding: 16px 18px; }
        .navbar .navbar-nav .nav-item .dropdown-button {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 46px;
          height: 46px;
          border-left: 1px solid #EDEDED;
          z-index: -1; }
          .navbar .navbar-nav .nav-item .dropdown-button:before {
            position: absolute;
            top: 19px;
            right: 22px;
            display: block;
            width: 2px;
            height: 8px;
            background-color: #17183b;
            content: ''; }
          .navbar .navbar-nav .nav-item .dropdown-button:after {
            position: absolute;
            top: 22px;
            right: 19px;
            display: block;
            width: 8px;
            height: 2px;
            background-color: #17183b;
            content: ''; }
        .navbar .navbar-nav .nav-item.dropdown.show .dropdown-button:before {
          content: unset; }
        .navbar .navbar-nav .nav-item .dropdown-menu {
          display: none;
          padding-top: 0;
          padding-bottom: 0; }
          .navbar .navbar-nav .nav-item .dropdown-menu.show {
            display: block; }
          .navbar .navbar-nav .nav-item .dropdown-menu li {
            border-top: 1px solid #EDEDED; }
            .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-item {
              padding: 10px 20px 10px 35px; } }

.banner-area {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 70px 0; }
  .banner-area:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8); }
  .banner-area .banner-text ul {
    margin: 0;
    padding: 0; }
    .banner-area .banner-text ul li {
      position: relative;
      display: inline-block;
      list-style: none;
      padding-left: 20px;
      color: #252525;
      font-weight: 600;
      font-size: 14px; }
      .banner-area .banner-text ul li.home-icon:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "\e801";
        font-family: 'fontello'; }
      .banner-area .banner-text ul li.left-icon:before {
        position: absolute;
        top: 1px;
        left: 6px;
        content: "\f105";
        font-family: 'fontello'; }
      .banner-area .banner-text ul li a {
        font-weight: 600;
        font-size: 14px; }
  .banner-area .banner-text h2 {
    margin-top: 10px;
    color: #252525;
    font-weight: 700; }

.news-details ul {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 30px; }
  .news-details ul li {
    list-style: none;
    margin-top: 15px;
    margin-bottom: 5px; }
    .news-details ul li:before {
      content: "\f105";
      font-family: 'fontello';
      margin-right: 10px; }

.news-details picture {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1rem; }
  .news-details picture img {
    width: 100%;
    height: auto; }

.widgets {
  display: block;
  padding-left: 50px; }
  .widgets .widget {
    margin-bottom: 40px; }
    .widgets .widget h4 {
      font-size: 20px; }
      .widgets .widget h4:before {
        height: 2px; }
    .widgets .widget .tag {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 15px;
      padding: 8px 20px;
      background-color: #F7F7F7;
      transition: all 0.3s ease 0s;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s; }
      .widgets .widget .tag:hover {
        color: #FFFFFF;
        background-color: #17183b; }
    .widgets .widget .media img {
      max-width: 100%;
      height: auto; }
    .widgets .widget .media h5 {
      font-size: 14px;
      line-height: 20px; }

@media screen and (max-width: 767px) {
  .widgets {
    padding-left: 0; } }

.team-list {
  margin-top: 20px; }
  .team-list .col {
    padding-bottom: 30px; }
  .team-list .team-item {
    width: auto;
    height: 100%;
    background-color: #F7F7F7;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
    .team-list .team-item:hover {
      -webkit-box-shadow: 0px 0px 35px rgba(51, 61, 76, 0.3);
      box-shadow: 0px 0px 35px rgba(51, 61, 76, 0.3); }
      .team-list .team-item:hover .photo:before {
        opacity: 1;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s; }
      .team-list .team-item:hover .photo .social ul li a {
        opacity: 1; }
        .team-list .team-item:hover .photo .social ul li a.linkedin {
          bottom: 50%; }
    .team-list .team-item .photo {
      position: relative;
      display: inline-block;
      margin: 0; }
      .team-list .team-item .photo:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 24, 59, 0.5);
        opacity: 0;
        content: ''; }
      .team-list .team-item .photo img {
        width: 100%;
        height: auto; }
      .team-list .team-item .photo .social {
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
        .team-list .team-item .photo .social ul {
          margin: 0;
          padding: 0; }
          .team-list .team-item .photo .social ul li {
            display: inline-block;
            list-style: none; }
            .team-list .team-item .photo .social ul li a {
              position: absolute;
              display: inline-block;
              width: 35px;
              height: 35px;
              border-radius: 100px;
              margin: 3px;
              text-align: center;
              line-height: 35px;
              color: #FFF;
              font-size: 16px;
              background-color: rgba(255, 255, 255, 0.5);
              opacity: 0; }
              .team-list .team-item .photo .social ul li a.linkedin {
                bottom: calc(50% - 30px);
                left: calc(50% - 30px);
                -webkit-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s; }
    .team-list .team-item .team-content {
      padding: 0 20px 0 0;
      text-align: justify; }
      .team-list .team-item .team-content .name {
        font-size: 18px;
        line-height: 25px;
        margin: 20px 0 10px 0; }
        .team-list .team-item .team-content .name .function {
          font-size: 14px;
          font-weight: 400; }

@media screen and (max-width: 632px) {
  .team-list .team-item .photo {
    width: 100%;
    padding: 20px; }
    .team-list .team-item .photo picture {
      display: block; }
  .team-list .team-item .team-content {
    padding: 0 20px; }
    .team-list .team-item .team-content .name .function {
      display: block; } }

.page-footer {
  background-color: #252525;
  padding: 85px 0px;
  color: #FFFFFF; }
  .page-footer a {
    color: #FFFFFF; }
  .page-footer .address {
    margin-top: 25px; }
    .page-footer .address .address-title {
      margin-bottom: 25px; }
  .page-footer ul {
    padding: 0; }
    .page-footer ul li {
      list-style: none;
      margin-bottom: 10px; }
      .page-footer ul li i {
        display: inline-block;
        width: 25px; }
  .page-footer h5 {
    color: #FFFFFF;
    margin-bottom: 33px;
    font-size: 18px; }

.copyright {
  background-color: #1F1F1F;
  padding: 12px 0px 3px; }
  .copyright p {
    color: #636363;
    font-size: 14px; }

.fonts-loaded [class^="icon-"]:before {
  font-family: "fontello"; }

.fonts-loaded [class*=" icon-"]:before {
  font-family: "fontello"; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

#map {
  width: 100%;
  height: 390px;
  background-color: #636363; }
