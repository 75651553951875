body {
    color: #636363;
    font-size: 14px;
    font-family: $text-font;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    color: $primary-color;
    font-weight: 700;
}

a {
    color: $primary-color;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
}

.btn {
    min-width: 150px;
    font-family: 'Open Sans', sans-serif;
    display: inline-block;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    letter-spacing: 1.1px;
    padding: 12px 30px;
    border-radius: 0;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;

    &.btn-primary {
        color: $white-color;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}

p {
    line-height: 24px;
}

.section-padding {
    padding: 35px 0px;
}

.title-line {
    position: relative;
    font-weight: 700;
    margin-bottom: 30px;

    &:before {
        content: '';
        position: absolute;
        width: 60px; height: 4px;
        left: 0; right: 0; bottom: -10px;
        background-color: $primary-color;
    }
}
.lead {
    color: $light-gray;
}

.error-page {
    padding: 194px 60px;
    h1 {
        font-size: 60px;
        font-weight: 700;
        line-height: 72px;
    }
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-break: 24px;
        margin-top: 0;
    }
    p {
        font-size: 14px;
    }
    .btn {
        margin-top: 20px;
        font-weight: 700;
    }
}

.mt-60 {
    margin-top: 60px;
}

.form-group {
    margin-bottom: 20px;

    .form-control {
        font-size: 14px;
        border-radius: 0;
        border: 1px solid #E7E7E7;
        padding: 0.5rem 1rem;

        &.is-invalid {
            border-color: #dc3545;
        }
        &.is-valid {
            border-color: #28a745;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $lighter-color;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $lighter-color;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $lighter-color;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $lighter-color;
        }
    }
}

input#dhsecrethoney {
    display: none;
}

.list-group {
    .list-item {
        list-style: none;
        margin-bottom: 5px;

        i {
            position: relative;
            display: inline-block;
            width: 35px; height: auto;
            font-size: 14px;

            &:after {
                content: '';
                position: absolute;
                right: 0; bottom: 4px;
                width: 2px; height: 12px;
                background-color: $light-gray;
            }
        }

        span {
            margin-left: 8px;
        }
    }
}

.quote {
    display: block;
    background-color: #EDEDED;
    padding: 25px 35px;
    margin: 30px 0px;
    &:before {
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
}

.alert {
    border-radius: 0;
}
