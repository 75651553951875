.banner-area {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 70px 0;

    &:before {
        content: '';
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0; bottom: 0; right: 0;
        background-color: rgba(255, 255, 255, 0.8);
    }

    .banner-text {
        ul {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                display: inline-block;
                list-style: none;
                padding-left: 20px;
                color: $dark-black;
                font-weight: 600;
                font-size: 14px;

                &.home-icon:before {
                    position: absolute;
                    top: 0; left: 0;
                    content: "\e801";
                    font-family: 'fontello';
                }

                &.left-icon:before {
                    position: absolute;
                    top: 1px; left: 6px;
                    content: "\f105";
                    font-family: 'fontello';
                }

                a {
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }

        h2 {
            margin-top: 10px;
            color: $dark-black;
            font-weight: 700;
        }
    }
}
