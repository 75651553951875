.team-list {
    margin-top: 20px;

    .col {
        padding-bottom: 30px;
    }
    .team-item {
        width: auto; height: 100%;
        background-color: #F7F7F7;
        margin-bottom: 30px;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;

        &:hover {
            -webkit-box-shadow: 0px 0px 35px rgba(51, 61, 76, 0.3);
            box-shadow: 0px 0px 35px rgba(51, 61, 76, 0.3);

            .photo {
                &:before {
                    opacity: 1;
                    -webkit-transition: all 0.3s ease 0s;
                    -o-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                }

                .social ul li a {
                    opacity: 1;

                    &.linkedin {
                        bottom: 50%;
                    }
                }
            }
        }

        .photo {
            position: relative;
            display: inline-block;
            margin: 0;

            &:before {
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
                width: 100%; height: 100%;
                background-color: $lighter-color;
                opacity: 0;
                content: '';
            }
            img {
                width: 100%; height: auto;
            }
            .social {
                position: absolute;
                top: 60%; left: 0; right: 0;
                text-align: center;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);

                ul {
                    margin: 0; 
                    padding: 0;
                    li {
                        display: inline-block;
                        list-style: none;

                        a {
                            position: absolute;
                            display: inline-block;
                            width: 35px; height: 35px;
                            border-radius: 100px;
                            margin: 3px;
                            text-align: center;
                            line-height: 35px;
                            color: #FFF;
                            font-size: 16px;
                            background-color: rgba(255, 255, 255, 0.5);
                            opacity: 0;

                            &.linkedin {
                                bottom: calc(50% - 30px);
                                left: calc(50% - 30px);
                                -webkit-transition: all 0.3s ease 0s;
                                -o-transition: all 0.3s ease 0s;
                                transition: all 0.3s ease 0s;
                            }
                        }
                    }
                }
            }
        }

        .team-content {
            padding: 0 20px 0 0;
            text-align: justify;

            .name {
                font-size: 18px;
                line-height: 25px;
                margin: 20px 0 10px 0;

                .function {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width:632px) {
    .team-list {
        .team-item {
            .photo {
                width: 100%;
                padding: 20px;
                picture {
                    display: block;
                }
            }
            .team-content {
                padding: 0 20px;
                .name .function {
                    display: block
                }
            }
        }
    }
}
