.news-details {
    ul {
        padding: 0;
        margin-top: 20px;
        margin-bottom: 30px;
        
        li {
            list-style: none;
            margin-top: 15px;
            margin-bottom: 5px;

            &:before {
                content: "\f105";
                font-family: 'fontello';
                margin-right: 10px;
            }
        }
    }
    picture {
        display: block;
        width: 100%; height: auto;
        margin-bottom: 1rem;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.widgets {
    display: block;
    padding-left: 50px;

    .widget {
        margin-bottom: 40px;
        
        h4 {
            font-size: 20px;
            &:before {
                height: 2px;
            }
        }
        
        .tag {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 15px;
            padding: 8px 20px;
            background-color: #F7F7F7;
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;

            &:hover {
                color: $white-color;
                background-color: $primary-color;
            }
        }
        .media {
            img {
                max-width: 100%;
                height: auto;
            }
            h5 {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgets {
        padding-left: 0;
    }
}
