$primary-color: #17183b;
$lighter-color: rgba(#17183b, 0.5);
$white-color: #FFFFFF;

$dark-black: #252525;

$dark-gray: #595959;
$light-gray: #636363;

$text-font: 'Open Sans', sans-serif;
$header-font: 'Open Sans', sans-serif;
$text-danger: #dc3545;
