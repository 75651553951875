.navbar {
    position: relative;
    width: 100%;
    background-color: $white-color;
    top: 0; z-index: 999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 0;

    .navbar-brand {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .nav-item {
        .dropdown-button {
            display: none;
        }
        &.dropdown {
            &:hover {
                .dropdown-menu {
                    left: auto;

                    li {
                        height: 39px;
                    }
                }
            }
        }
        .nav-link {
            padding: 33px 18px;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            line-height: 1;

            &.dropdown-toggle:after {
                content: unset;
            }

            &.btn-primary {
                padding: 15px;
                margin: 18px 0px 18px 18px;
                color: $white-color;
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
        .dropdown-menu {
            display: block;
            left: -9999px;
            border: none;
            border-radius: 0;
            margin: 0;

            li {
                height: 0;
                transition: all .25s ease;
                -webkit-transition: all .25s ease;
                -ms-transition: all .25s ease;

                .dropdown-item {
                    padding: 10px 20px;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    &:hover {
                        background-color: #EDEDED;
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .navbar {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;

        .mobile-header {
            position: relative;
            width: 100%; height: auto;
            padding: 5px 0;

            .navbar-toggler {
                position: absolute;
                top: 25px; right: 0;
                width: 55px; height: 46px;
                z-index: 9999;
                outline: 0;
                cursor: pointer;

                &:before {
                    position: absolute;
                    top: 17px; right: 10px;
                    display: block;
                    width: 20px; height: 2px;
                    background-color: $primary-color;
                    content: '';
                }
                .navbar-toggler-span {
                    position: absolute;
                    top: 23px; right: 10px;
                    display: block;
                    width: 20px; height: 2px;
                    background-color: $primary-color;
                    content: '';
                }
                &:after {
                    position: absolute;
                    top: 29px; right: 10px;
                    display: block;
                    width: 20px; height: 2px;
                    background-color: $primary-color;
                    content: '';
                }
            }
        }

        .navbar-nav {
            width: 100%;

            .nav-item {
                position: relative;
                width: 100%; height: auto;
                border-top: 1px solid #EDEDED;
               
                .nav-link {
                    padding: 16px 18px;
                }
                .dropdown-button {
                    position: absolute;
                    top: 0; right: 0;
                    display: block;
                    width: 46px; height: 46px;
                    border-left: 1px solid #EDEDED;
                    z-index: -1;

                    &:before {
                        position: absolute;
                        top: 19px; right: 22px;
                        display: block;
                        width: 2px; height: 8px;
                        background-color: $primary-color;
                        content: '';
                    }
                    &:after {
                        position: absolute;
                        top: 22px; right: 19px;
                        display: block;
                        width: 8px; height: 2px;
                        background-color: $primary-color;
                        content: '';
                    }
                }
                &.dropdown.show .dropdown-button:before {
                    content: unset;
                }

                .dropdown-menu {
                    display: none;
                    padding-top: 0;
                    padding-bottom: 0;
                    &.show {
                        display: block;
                    }
                    li {
                        border-top: 1px solid #EDEDED;
                        .dropdown-item {
                            padding: 10px 20px 10px 35px;
                        }
                    }
                }
            }
        }
    }
}
