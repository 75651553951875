.page-footer {
    background-color: #252525;
    padding: 85px 0px;
    color: $white-color;
    
    a { color: $white-color; }

    .address {
        margin-top: 25px;
        .address-title { margin-bottom: 25px; }
    }

    ul {
        padding: 0;
        li {
            list-style: none;
            margin-bottom: 10px;
            i {
                display: inline-block;
                width: 25px;
            }
        }
    }

    h5 {
        color: $white-color;
        margin-bottom: 33px;
        font-size: 18px;
    }
}

.copyright {
    background-color: #1F1F1F;
    padding: 12px 0px 3px;
    p {
        color: #636363;
        font-size: 14px;
    }
}
